.Header {
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */
    width: 100%;
    justify-content: space-between;
    /* padding: 0.5rem 0rem; */
}

.Header img {
    width: min(75%, 500px);
    cursor: pointer;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.25rem 2rem;
}

.search {
    display: none;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.Header select {
    padding: 0.4rem 0.6rem;
    border-radius: 1rem;
    cursor: pointer;
    height: fit-content;
    font-size: 0.85rem;
}

.Header option {
    padding: 0.2rem 0.4rem;
}

.Header button {
    padding: 0.6rem 0.8rem;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    transition: all 200ms;
    font-weight: 700;
    color: #5B83D1;
}

.Header button:hover {
    background-color: #5B83D1;
    color: aliceblue;
}

.search svg {
    color: #5B83D1;
    height: 2rem;
    aspect-ratio: 1 / 1;
    width: fit-content;
    align-self: flex-end;
    cursor: pointer;
}

.search div {
    display: flex;
    gap: 0.5rem;
    align-self: flex-end;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.category {
    background-color: #5B83D1;
    padding: 0.5rem 2rem;
    gap: 1rem;
    display: flex;
    overflow: auto;
}

.category::-webkit-scrollbar {
    display: none;
}

.category>button {
    padding: 0.4rem 0.8rem;
    white-space: nowrap;
}

.sidebarBtn {
    cursor: pointer;
}

.Sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 200px;
    background-color: #5B83D1;
    transition: all 300ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
    border-left: 2px solid black;
    z-index: 9999;
}

.show {
    right: 0;
}

.hide {
    right: -200px;
}

.Sidebar svg {
    font-size: 1.5rem;
    color: aliceblue;
    cursor: pointer;
}

.Sidebar button {
    width: 100%;
}

.Sidebar>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.Sidebar>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.Sidebar select {
    width: 100%;
}

.popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.25rem 1rem;
    background-color: #5b83d1;
    /* border-radius: 0 0 1rem 1rem; */
}

.popup>* {
    width: 100%;
}

.popup h3 {
    font-weight: 800;
    font-size: 0.875rem;
    color: azure;
}

.popup button {
    box-shadow: inset 0 0 6px 2px black;
}

.popup svg {
    color: white;
    font-weight: 800;
    font-size: 1.125rem;
    cursor: pointer;
    width: 20%;
}

.breakingNews{
    display: flex;
    justify-content: flex-start;
}

.breakingNews>p{
    background-color: red;
    padding: 0.45rem 0.65rem;
    color: white;
    font-weight: 700;
    white-space: nowrap;
}
.breakingNews>h4{
    background-color: antiquewhite;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    padding: 0 0 0 0.5rem;
}

@media (min-width: 768px) {
    .sidebarBtn {
        display: none;
    }

    .search {
        display: flex;
    }

    .top {
        align-items: flex-end;
    }
}
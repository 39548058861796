.Pagination{
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.Pagination button{
    padding: 0.6rem 0.8rem;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    transition: all 200ms;
    font-weight: 700;
    color: aliceblue;
    background-color: #5B83D1;
}
.Pagination h2{
    font-size: 1rem;
    font-weight: 600;
    color: #5B83D1;
    cursor: pointer;
}
.Pagination p{
    padding: 0.375rem 0.5rem;
    border-radius: 1000px;
    aspect-ratio: 1 / 1;
    font-size: 1rem;
    font-weight: 600;
    background-color: #5B83D1;
    color: aliceblue;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Utils {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    color: #5B83D1;
}

.Utils>div {
    border-radius: 1rem;
    width: 100%;
    flex-grow: 1;
    background-color: #E3E8F1;
    padding: .5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.Utils button {
    display: flex;
    font-size: 1.125rem;
    color: #FFF;
    font-weight: 700;
    padding: 0.5rem 0.75rem;
    border-radius: 1rem;
    background: #8E9EBC;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    transition: all 200ms;
}

.Utils button:hover {
    background-color: #E3E8F1;
    color: #5B83D1;
}

.Utils img {
    width: min(100%, 300px);
    border-radius: 1rem;
}

.Utils h1 {
    color: #5B83D1;
    text-align: start;
    text-decoration: underline;
}

.Utils ul {
    width: 100%;
    padding: 2rem;
    text-align: left;
}

.Utils a{
    text-transform: lowercase;
}

.Utils p{
    font-weight: 600;
    text-align: left;
}

.Utils .utilImages{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 1rem;
}

.Utils .utilImages>div{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.Utils span{
    font-weight: 800;
    text-align: right;
}

.Utils .utilImages img{
    height: 400px;
    object-fit: cover;
}

.Utils h2{
    text-align: left;
    font-weight: 800;
    font-size: 1.125rem;
}
.Utils h3{
    text-align: left;
    font-weight: 600;
    font-size: 1rem;
}

@media (max-width: 480px) {
    .Utils .utilImages{
        flex-direction: column;
    }
}
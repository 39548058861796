.Login {
    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;
    background: #E3E8F1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.Login>h2 {
    color: #5B83D1;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 2rem;
}

.Login>div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
}

.Login h4 {
    color: #000;
    width: 100%;
    text-align: left;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Login>div>div {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
    background: #FFF;
    align-items: flex-start;
    gap: 0.625rem;
}

.Login input {
    border-radius: 0.5rem;
    padding: 0.75rem 0.5rem;
    background-color: #FFF;
    border: none;
    font-size: 1.125rem;
    padding-right: 3rem;
    width: 100%;
    text-transform: none;
}

.Login svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    font-size: 1.25rem;
    cursor: pointer;
}

.btn {
    display: flex;
    font-size: 1.125rem;
    color: #FFF;
    font-weight: 700;
    padding: 0.5rem 0.75rem;
    border-radius: 1rem;
    background: #8E9EBC;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    cursor: pointer;
    transition: all 200ms;
}

.btn:hover {
    background-color: #E3E8F1;
    color: #5B83D1;
}

.selectedBtn {
    background-color: #E3E8F1;
    color: #5B83D1;
}

.Login p {
    font-size: 0.875rem;
    font-weight: 800;
    color: #5B83D1;
    text-decoration: underline;
    cursor: pointer;
}

.Options {
    padding: 0.5rem;
    border-radius: 1rem;
    background: #E3E8F1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem
}

.Options h1 {
    color: #5B83D1;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.Options>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.ManageNews {
    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;
    background: #E3E8F1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
}

.ManageNews h1 {
    color: #5B83D1;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.ManageNews>div {
    padding: 0 0.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
}

.ManageNews>div>div {
    width: 100%;
}

.twoOptions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    border-radius: 1rem;
    padding: 0.25rem;
    cursor: pointer;
}

.allNews {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.25rem;
    background-color: #FFF;
    border-radius: 1rem;
    flex-grow: 1;
}

.singleNews {
    display: flex;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: #8E9EBC;
    color: #FFF;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.15rem;
    align-self: stretch;
    cursor: pointer;
    transition: all 200ms;
}

.singleNews:hover {
    background-color: #E3E8F1;
    color: #5B83D1;
}

.singleNews h2 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    max-width: 80%;
}

.singleNews div {
    width: 100%;
    display: flex;
    align-items: center;
}

.singleNews>div:nth-child(1) {
    justify-content: space-between;
}

.singleNews h3 {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.singleNews h4 {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.CreateNews {
    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;
    background: #E3E8F1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.CreateNews h1 {
    color: #5B83D1;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.CreateNews>div {
    padding: 0 0.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.CreateNews>div>div {
    width: 100%;
}

.imageSection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.imageSection>div {
    width: 100%;
}

.imageSection h2 {
    color: #FFF;
}

.tempImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    background-color: #8E9EBC;
    cursor: pointer;
    border-radius: 1rem;
}

.detailSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.detailSection select {
    padding: 0.4rem 0.6rem;
    border-radius: 1rem;
    cursor: pointer;
    height: fit-content;
    font-size: 0.85rem;
    width: 100%;
    background-color: #8E9EBC;
    font-weight: 600;
    color: #FFF;
}

.detailSection option {
    padding: 0.2rem 0.4rem;
}

.detailSection>div:nth-child(1) {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.detailSection>div:nth-child(2) {
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
}

.detailSection input,
textarea {
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    background-color: #FFF;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    text-transform: none;
    resize: none;
}

.imageContainer {
    width: 100%;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
}

.imageContainer img {
    border-radius: 1rem;
}
.imageContainer video {
    border-radius: 1rem;
}

.imageContainer>svg {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 2rem;
    color: #8E9EBC;
    cursor: pointer;
    z-index: 1;
}

.paragraphContainer {
    width: 100%;
    border-radius: 1rem;
    position: relative;
    background-color: #8E9EBC;
    color: #FFF;
    padding: 2.25rem 1rem;
    font-weight: 700;
}

.paragraphContainer>svg {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 1.5rem;
    color: #E3E8F1;
    cursor: pointer;
}

.paragraphContainer>p {
    width: 100%;
    overflow-wrap: break-word;
    text-align: left;
}

.Manage {
    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;
    background: #E3E8F1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
}

.Manage h1 {
    color: #5B83D1;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.Manage>div {
    padding: 0 0.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
}

.loaderBg {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100%;
    border-radius: 1rem;
}

.addingNew {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.addingNew input {
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    background-color: #FFF;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    text-transform: none;
    resize: none;
    flex-grow: 1;
}

.addingNew button {
    width: auto;
}

.singleCategory {
    width: 100%;
    display: flex;
    border-radius: 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.45rem 0.65rem;
    background-color: #8e9ebc;
    color: white;
}

.singleCategory h2 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    flex-grow: 1;
    text-align: start;
    text-overflow: ellipsis;
    width: 45%;
    text-wrap: wrap;
    overflow: hidden;
}

.singleCategory svg {
    cursor: pointer;
    font-size: 1rem;
}

.singleCategory input {
    border-radius: 0.5rem;
    padding: 0rem 0.25rem;
    background-color: #e3e8f1;
    border: none;
    font-size: 1rem;
    width: 100%;
    text-transform: none;
}

.singleCategory div {
    display: flex;
    gap: 0.75rem;
}